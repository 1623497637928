<template>
    <div class="flex w-full px-5 lg:px-10 justify-center">
        <div class="flex max-w-page w-full items-center gap-1">
            <img src="~/assets/icons/facebook-r.svg" height="30" width="30" class="w-[30px]" alt="fb"/>
            <span class="text-white font-semibold">
                Facebook 4.7/5 - 2780 Reviews
            </span>
        </div>
    </div>
</template>
